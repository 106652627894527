.header {
	background-color: #e7fbff; /* Change Color */
	display: flex;
	font-family: Inter, sans-serif;
	/* font-weight: bold; */
}

.text {
	color: #173148;
}

.btn {
	color: #e0f1f8;
	background-color: #173148;
	border-radius: 12px;
}
.btn:hover {
	color: #173148;
	background-color: #e0f1f8;
}
.login {
	border-radius: 12px;
	/* padding-top: 3px; */
	background-color: transparent;
	border-width: 1px solid thin;
	margin-right: 8px;
	text-decoration: underline;
	/* font-weight: bold; */
	color: #173148;
}
.login:hover {
	color: #e0f1f8;
	background-color: #173148;
}
.link:active {
	/* border-radius: 12px; */

	/* text-decoration: none; */
	/* color: #333; Adjust the color as needed */
	/* font-weight: bold; */
	/* background-color: red; */
}
