.nav-tabs a.nav-link.active,
.nav-tabs a.nav-link.active:hover,
.nav-tabs a.nav-link.active:focus,
.nav-tabs p.nav-link.active,
.nav-tabs p.nav-link.active:hover,
.nav-tabs p.nav-link.active:focus {
	border-style: none;
	background-color: #e7fbff;
	font-weight: bold;
}
.nav-tabs,
.nav-tabs a.nav-link,
.nav-tabs p.nav-link {
	border-style: none;
}
.nav-tabs > li > a:hover {
	text-decoration: underline #173148;
}
.nav-tabs > li > p:hover {
	text-decoration: underline #173148;
}
