.container1 {
	background-color: #e7fbff;
	padding: 2%;
	color: #173148;
	text-align: left;
	/* font-family: "Inter"; */
}
.image1 {
	max-width: 100%;
	display: block;
	margin-left: auto;
	padding: 2%;
}

.btn {
	background-color: #e0f1f8;
	font-style: italic;
}
.btn:hover {
	background-color: #e0f1f8;
	color: #173148;
	font-weight: bold;
}
.block1 {
	padding-left: 5%;
	padding-top: 10%;
}
