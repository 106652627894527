.container3 {
	background-color: #173148;
	color: white;
}
.textContainer {
	text-align: left;
	padding-top: 5px;
}

.text1 {
	padding-right: 40px;
}

.text2 {
	padding-right: 40px;
	padding-top: 20px;
}
.headText {
	text-align: left;
}
