.container4 {
	background-color: #e7fbff;
	padding: 2%;
	color: #173148;
	/* font-family: "Inter"; */
}
.image1 {
	max-width: 100%;
	display: block;
	margin-left: auto;
	padding: 2%;
}
.text {
	text-align: left;
	padding: 5%;
}

.btn,
.btn:hover {
	background-color: #e0f1f8;
	font-style: italic;
	color: #173148;
}
.text1 {
	padding-top: 10%;
	text-align: right;
}
