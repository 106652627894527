.preFooter {
	background-color: #e7fbff;
	padding-top: 20px;
	font-family: "Inria Sans", sans-serif;
	border-top: 1px solid #bcbcbc;
}
.text {
	font-size: 23px;
}
.ul {
	list-style-type: none;
}
#ul > li {
	padding-bottom: 15px;
}

#ul > li > b {
	font-size: 50;
}
.center {
	display: flex;
	align-items: center;
	height: 200px;
}
/* .image {
	width: 10px;
	height: 20px;
} */
