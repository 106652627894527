.container2 {
	background-color: #e7fbff;
	/* padding: 2%; */
	color: #173148;
	font-family: Inter, sans-serif;
}
.image1 {
	max-width: 100%;
	display: block;
	margin-left: auto;
	/* padding: 2%; */
	height: 100%;
}
.text1 {
	text-align: left;
	padding-top: 50px;
}
.text2 {
	text-align: left;
}
.text3 {
	padding-left: 6%;
	font-family: Inter, sans-serif;
	font-style: italic;
	text-align: center;
}
.btn,
.btn:hover {
	background-color: #e0f1f8;
	font-style: italic;
	color: #173148;
}
.finalText {
	text-align: right;
}
