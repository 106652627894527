.container7 {
	background-color: #173148;
	padding: 2%;
	color: #e7fbff;
	/* font-family: "Inter"; */
}
.text {
	text-align: center;
	padding: 2%;
}
.textContainer {
	text-align: center;
	padding-top: 15px;
}
.imgContainer {
	text-align: center;
}
