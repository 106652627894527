.container1 {
	text-align: center;
	background-color: #e7fbff;
}
.textContainer {
	padding-inline: 11%;
	padding-block: 10px;
}
.text {
	padding-block: 15px;
}
.image1 {
	/* size: 100%; */
}
